/**
 * External Dependencies
 */
import daxios from "../clients/directAxios";
import handleError from "../errors/handleError";
// import Tokenizer from "../helpers/tokenizer";

import { ApiEndpoint } from "../config";

// export const auth_loginError = () => ({
//   type: "AUTH_LOGIN_ERROR"
// });

const api = new ApiEndpoint("landing");
const dialogApi = new ApiEndpoint("dialog");

export const APP_DATA = "APP_DATA";
export const getAppData = (city) => {
  return function (dispatch, getState) {
    return daxios
      .get(api.endpoint("/city/" + city))
      .then((res) => {
        if (!res.data) {
          const error = {
            response: {
              data: {
                message: "Город не найден",
              },
            },
          };
          handleError(error, getState(), dispatch);

          return Promise.reject(error);
        }

        dispatch({
          type: APP_DATA,
          payload: {
            ...res.data,
          },
        });

        return Promise.resolve(res.data);
      })
      .then((data) => {
        // ...
        return Promise.resolve(data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const DIALOG_DATA = "DIALOG_DATA";
export const getDialogData = (id, platform_id) => (dispatch, getState) =>
  daxios
    .get(dialogApi.endpoint(`/landing/${id}/bots`, { platform_id }))
    .then((res) => {
      dispatch({
        type: DIALOG_DATA,
        payload: res.data,
      });

      return Promise.resolve(res.data);
    })
    .catch((error) => {
      handleError(error, getState(), dispatch);

      return Promise.reject(error);
    });

export const POST_DIALOG = "POST_DIALOG";
export const postDialog = (payload) => (dispatch, getState) =>
  daxios
    .post(dialogApi.endpoint("/send"), payload)
    .then((res) => {
      if (!res.data.success) {
        const error = {
          response: {
            data: {
              message: "Диалоги не найдены",
            },
          },
        };
        handleError(error, getState(), dispatch);

        return Promise.reject(error);
      }

      dispatch({
        type: POST_DIALOG,
        payload: {
          ...res.data,
        },
      });

      return Promise.resolve(res.data);
    })
    .then((data) => {
      // ...
      return Promise.resolve(data);
    })
    .catch((error) => {
      handleError(error, getState(), dispatch);

      return Promise.reject(error);
    });

export const SET_CATEGORY = "SET_CATEGORY";
export const setCategory = (category) => {
  return {
    type: "AUTH_LOGIN_ERROR",
    category,
  };
};

export const GET_IDEA = "GET_IDEA";
export const getIdea = (city, id) => {
  return function (dispatch, getState) {
    return daxios
      .get(api.endpoint("/city/" + city + "/ideas/" + id))
      .then((res) => {
        dispatch({
          type: GET_IDEA,
          payload: res.data,
        });

        return Promise.resolve(res.data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const GET_MAP_IDEAS = "GET_MAP_IDEAS";
export const getMapIdeas = (city) => {
  return function (dispatch, getState) {
    return daxios
      .get(
        api.endpoint("/city/" + city + "/ideas", {
          page_size: 1000,
        })
      )
      .then((res) => {
        // dispatch({
        //     type: GET_MAP_IDEAS,
        //     payload: res.data
        // });

        return Promise.resolve(res.data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const GET_POP_IDEAS = "GET_POP_IDEAS";
export const getPopIdeas = (city) => {
  return function (dispatch, getState) {
    return daxios
      .get(
        api.endpoint("/city/" + city + "/ideas", {
          page_size: 9,
        })
      )
      .then((res) => {
        dispatch({
          type: GET_POP_IDEAS,
          payload: res.data,
        });

        return Promise.resolve(res.data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const IDEAS_DATA = "IDEAS_DATA";
export const getIdeasData = (city) => {
  return function (dispatch, getState) {
    return daxios
      .get(
        api.endpoint("/city/" + city + "/ideas", {
          page_size: 1000,
        })
      )
      .then((res) => {
        if (!res.data) {
          const error = {
            response: {
              data: {
                message: "Предложения не найдены",
              },
            },
          };
          handleError(error, getState(), dispatch);

          return Promise.reject(error);
        }

        dispatch({
          type: IDEAS_DATA,
          payload: {
            ...res.data,
          },
        });

        return Promise.resolve(res.data);
      })
      .then((data) => {
        // ...
        return Promise.resolve(data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const GET_CAT_IDEAS = "GET_CAT_IDEAS";
export const getCatIdeas = (city, cat_id, page = 0) => {
  return function (dispatch, getState) {
    const catPayload = {
      page,
      page_size: 12,
      ...(cat_id ? { categories: cat_id } : {}),
    };

    return daxios
      .get(api.endpoint("/city/" + city + "/ideas", catPayload))
      .then((res) => {
        dispatch({
          type: GET_CAT_IDEAS,
          payload: res.data,
        });

        return Promise.resolve(res.data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const POST_IDEA = "POST_IDEA";
export const postIdea = (city, payload) => {
  return function (dispatch, getState) {
    return daxios
      .post(api.endpoint("/city/" + city + "/idea"), payload)
      .then((res) => {
        if (!res.data.success) {
          const error = {
            response: {
              data: {
                message: "Предложения не найдены",
              },
            },
          };
          handleError(error, getState(), dispatch);

          return Promise.reject(error);
        }

        dispatch({
          type: POST_IDEA,
          payload: {
            ...res.data,
          },
        });

        return Promise.resolve(res.data);
      })
      .then((data) => {
        // ...
        return Promise.resolve(data);
      })
      .catch((error) => {
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};
