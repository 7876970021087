import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

const Arrow = ({
  currentSlide,
  slideCount,
  slidesPerRow = 1,
  prev,
  ...props
}) => {
  const isVisible = currentSlide !== (prev ? 0 : slideCount - slidesPerRow);

  return isVisible ? (
    <Button
      className={`slick-${prev ? "prev" : "next"} slick-arrow`}
      icon={prev ? <LeftOutlined /> : <RightOutlined />}
      {...props}
    />
  ) : null;
};

export default Arrow;
