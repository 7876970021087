import React from "react";
import styled from "styled-components";
import { Carousel as Slider, Image } from "antd";

import breakpoints from "../theme/breakpoints";
import Arrow from "./arrow";

const fallback =
  "data:image/svg+xml,%3Csvg width='196' height='196' viewBox='0 0 196 196' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='196' height='196' fill='%23FAFAFA'/%3E%3Cpath d='M119.331 76.6666H76.6641C73.7228 76.6666 71.3308 79.0586 71.3308 82V114C71.3308 116.941 73.7228 119.333 76.6641 119.333H119.331C122.272 119.333 124.664 116.941 124.664 114V82C124.664 79.0586 122.272 76.6666 119.331 76.6666ZM83.3308 84.6666C84.3917 84.6666 85.4091 85.0881 86.1592 85.8382C86.9094 86.5883 87.3308 87.6058 87.3308 88.6666C87.3308 89.7275 86.9094 90.7449 86.1592 91.4951C85.4091 92.2452 84.3917 92.6666 83.3308 92.6666C82.2699 92.6666 81.2525 92.2452 80.5024 91.4951C79.7522 90.7449 79.3308 89.7275 79.3308 88.6666C79.3308 87.6058 79.7522 86.5883 80.5024 85.8382C81.2525 85.0881 82.2699 84.6666 83.3308 84.6666V84.6666ZM97.9975 111.333H79.3308L89.9975 98L93.9975 103.333L101.997 92.6666L116.664 111.333H97.9975Z' fill='%238C8C8C'/%3E%3C/svg%3E%0A";

const Container = styled.div`
  .ant {
    &-carousel {
      margin-bottom: 2rem;
      user-select: none;
      background-color: var(--color-grey-lighter-alt);

      .slick {
        &-slide > div {
          position: relative;
          display: flex;
          justify-content: center;
        }

        &-dots {
          &-bottom {
            bottom: -2rem;
          }

          li button {
            background-color: var(--color-grey-dark);
          }
        }

        &-track {
          display: flex;
          align-items: center;
        }

        &-prev,
        &-next {
          z-index: 10;
          margin-top: -1rem;
          height: 2rem;
          width: 2rem;
          padding: 0.25rem;
          font-size: 0.75rem;
          color: var(--color-primary);
          background: var(--color-white);
          border: 0.0625rem solid var(--color-primary);
          > .anticon {
            line-height: 0.75;
          }
        }

        &-prev {
          left: 1rem;
        }

        &-next {
          right: 1rem;
        }
      }
    }
  }
`;

const Carousel = ({ images }) => (
  <Container>
    <Slider
      infinite={false}
      dots={false}
      arrows
      draggable
      nextArrow={<Arrow />}
      prevArrow={<Arrow prev />}
      responsive={[
        {
          breakpoint: breakpoints?.md,
          settings: {
            arrows: false,
          },
        },
      ]}
    >
      {images?.map(({ url: src }, i) => (
        <Image key={i} {...{ src, fallback }} alt="" preview={false} />
      ))}
    </Slider>
  </Container>
);

export default Carousel;
