import React from "react";
import { List, Empty, Grid, Space } from "antd";
import styled from "styled-components";

const grid = {
  gutter: [20, 4],
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 3,
};

const { useBreakpoint } = Grid;

const StyledList = styled(List)`
  .ant {
    &-row > div {
      display: flex;
    }

    &-col {
      display: flex;
      flex-direction: column;

      > .ant-list-item {
        height: 100%;
      }
    }

    &-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Catalog = ({
  data,
  loading,
  component: Component,
  children,
  emptyText,
  current,
  onChange,
  pageSize,
  total,
}) => {
  const { sm } = useBreakpoint();

  return (
    <Space direction="vertical" size={48}>
      {children}
      <StyledList
        {...{ grid }}
        itemLayout="vertical"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
        }}
        pagination={
          (current || onChange || pageSize || total) && {
            ...(current ? { current } : {}),
            ...(onChange ? { onChange } : {}),
            ...(pageSize ? { pageSize } : {}),
            ...(total ? { total } : {}),
            showSizeChanger: false,
            hideOnSinglePage: true,
            showLessItems: !sm,
          }
        }
        dataSource={loading ? [...Array(pageSize || 12).keys()] : data}
        renderItem={(item, i) => (
          <List.Item key={i}>
            <Component {...{ ...item, loading }} />
          </List.Item>
        )}
      />
    </Space>
  );
};

export default Catalog;
