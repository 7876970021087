import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Row, Col } from "antd";

import Meta from "../components/meta";
// import About from "../components/about";
import breakpoints from "../theme/breakpoints";
import PlacesMap from "../components/map/places-map";

const Container = styled.div`
  position: relative;
`;
// const Info = styled(Col)`
//   @media screen and (min-width: ${breakpoints.lg}px) {
//     max-width: 24rem;
//   }
//
//   @media screen and (min-width: ${breakpoints.xl}px) {
//     max-width: 32rem;
//   }
// `;
const Map = styled(Col)`
  @media screen and (min-width: ${breakpoints.lg}px) {
    flex: 1;
  }
`;

const Main = () => {
  const { page } = useSelector(({ app }) => app);

  return (
    <Container>
      <Meta {...{ page }} />
      <Row>
        {/*<Info offset={2} span={20} lg={{ offset: 0, span: 24 }}>*/}
        {/*  <About {...{ page }} />*/}
        {/*</Info>*/}
        <Map span={24}>
          <PlacesMap />
        </Map>
      </Row>
    </Container>
  );
};

export default Main;
