import { gql } from "@apollo/client";

const AUTH_QUERY = gql`
  query tokenQuery($slug: String!) {
    token(input: { slug: $slug })
      @rest(type: "Token", path: "/project/auth", method: "POST") {
      project_id
      landing_id
      access_token
    }
  }
`;

const IDEA_FRAGMENT = gql`
  fragment ideaFragment on dialog_chat_idea_sat {
    cr
    date
    message
    location
    photos
    rating
    round
    id
    category
    chat_user {
      first_name
      last_name
      full_name
      id
      nickname
    }
  }
`;

const IDEAS_QUERY = gql`
  query ideasQuery(
    $pageSize: Int = 1000
    $offset: Int = 0
    $ids: [bigint!]
    $user: uuid
    $categories: [String!]
  ) {
    ideas(
      limit: $pageSize
      offset: $offset
      order_by: { cr: desc_nulls_last }
      where: {
        id: { _in: $ids }
        category: { _in: $categories }
        chat_user: { id: { _eq: $user } }
      }
    ) {
      ...ideaFragment
    }
    idea_aggregate(
      where: {
        id: { _in: $ids }
        category: { _in: $categories, _is_null: false }
        chat_user: { id: { _eq: $user } }
      }
    ) {
      aggregate {
        count
      }
    }
  }

  ${IDEA_FRAGMENT}
`;

const IDEA_QUERY = gql`
  query ideaQuery($id: bigint!) {
    idea(id: $id) {
      ...ideaFragment
    }
  }

  ${IDEA_FRAGMENT}
`;

const CATEGORIES_QUERY = gql`
  query categoriesQuery {
    ideas(distinct_on: category, order_by: { category: asc_nulls_last }) {
      category
    }
  }
`;

const LOCATIONS_QUERY = gql`
  query locationsQuery {
    locations @rest(type: "Locations", path: "/locations") {
      features
    }
  }
`;

const LOCATION_QUERY = gql`
  query locationQuery($id: uuid!) {
    location(id: $id) @rest(type: "Location", path: "/locations/{args.id}") {
      id
      name
      description
      priority
      todo
      notes
      geom
      ideas
    }
  }
`;

const PLACE_QUERY = gql`
  query placeQuery($query: String) {
    suggestions(
      input: {
        query: $query
        count: 10
        from_bound: { value: "city" }
        to_bound: { value: "settlement" }
      }
    ) @rest(type: "Suggestion", path: "/", method: "POST") {
      value
      data @type(name: "Place") {
        country
        region
        city
        geo_lat
        geo_lon
      }
    }
  }
`;

const PARTICIPANT_FRAGMENT = gql`
  fragment participantFragment on dialog_chat_user_sat {
    id
    photo
    full_name
    description
    profile
  }
`;

const PARTICIPANTS_QUERY = gql`
  query participantsQuery {
    participants: dialog_chat_user_sat(
      where: { role: { _eq: "participant" } }
    ) {
      ...participantFragment
    }
  }

  ${PARTICIPANT_FRAGMENT}
`;

const PARTICIPANT_QUERY = gql`
  query participantQuery($id: uuid!) {
    participants: dialog_chat_user_sat(where: { id: { _eq: $id } }) {
      ...participantFragment
    }
    ideas(where: { chat_user: { id: { _eq: $id } } }) {
      ...ideaFragment
    }
  }

  ${PARTICIPANT_FRAGMENT}
  ${IDEA_FRAGMENT}
`;

export {
  AUTH_QUERY,
  IDEAS_QUERY,
  IDEA_QUERY,
  CATEGORIES_QUERY,
  LOCATIONS_QUERY,
  LOCATION_QUERY,
  PARTICIPANTS_QUERY,
  PARTICIPANT_QUERY,
  PLACE_QUERY,
};
