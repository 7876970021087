import React from "react";
import { Button, Card, Layout, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";

import breakpoints from "../theme/breakpoints";
import IdeaDescription from "./idea-description";
import PlaceDescription from "./place-description";

const { Sider } = Layout;

const SiderContainer = styled(Sider)`
  &.ant-layout-sider {
    max-height: 100%;
    overflow: hidden;

    &-collapsed .ant-card {
      opacity: 0;
    }
  }

  .ant {
    &-card {
      display: flex;
      flex-direction: column;
      padding-top: 4rem;
      max-height: 100vh;
      height: 100%;
      width: 20rem;
      overflow: hidden;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      &-head {
        flex-shrink: 0;

        &-title {
          font-weight: 700;
          white-space: normal;
          color: rgba(0, 0, 0, 0.75);
        }
      }

      &-body {
        overflow-y: auto;
      }

      &-extra .ant-btn {
        color: rgba(0, 0, 0, 0.45);
        transition: color 0.3s;

        :hover {
          color: rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
`;

const ModalContainer = styled.div`
  .ant-modal {
    padding: 0;
    width: 100%;
    height: 100%;

    @media (max-width: ${breakpoints.lg}px) {
      max-width: 100%;
      margin: 0;
      border: 0.0625rem solid #f0f0f0;
    }

    &-title {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.75);
    }

    &-wrap {
      position: absolute;
      top: 4rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      box-shadow: none;
    }

    &-body {
      overflow-y: auto;
    }
  }
`;

const MapSider = ({ visible, object, onCancel, breakpoint, bordered }) => {
  const { __typename, name } = { ...object };
  const isIdea = __typename === "dialog_chat_idea_sat";
  const Description = isIdea ? IdeaDescription : PlaceDescription;
  const title = isIdea ? "Предложение" : name || "Нет названия";

  return breakpoint ? (
    <SiderContainer
      collapsible
      collapsed={!visible}
      collapsedWidth={0}
      width={320}
    >
      <Card
        {...{ title, bordered }}
        extra={
          <Button onClick={onCancel} type="link" icon={<CloseOutlined />} />
        }
      >
        <Description {...{ object }} />
      </Card>
    </SiderContainer>
  ) : (
    <ModalContainer>
      <Modal
        {...{ title, visible, onCancel }}
        centered
        getContainer={false}
        mask={false}
        maskClosable={false}
        footer={null}
        width="100%"
        zIndex={75}
      >
        <Description {...{ object }} />
      </Modal>
    </ModalContainer>
  );
};

export default MapSider;
