import { meadow, primary, salmon } from "../theme/colors";

export const NAVY = "Navy";
export const MAGENTA = "Magenta";
export const ROSE = "Rose";
export const SALMON = "Salmon";
export const GOLD = "Gold";
export const MEADOW = "Meadow";
const iconColors = [NAVY, MAGENTA, ROSE, SALMON, GOLD, MEADOW];
const dimensions = {
  width: 512,
  height: 512,
};
const handleIconMapping = (name, y) => ({
  [`marker${name || ""}`]: { x: 0, y, ...dimensions },
  [`remove${name || ""}`]: { x: 512, y, ...dimensions },
});
export const iconMapping = iconColors
  .map((icon, i) => handleIconMapping(icon, i * 512))
  ?.reduce((acc, val) => ({ ...acc, ...val }), {});

export const sightIdeas = "Достопримечательности";
export const botIdeas = "Заметки участников";
export const landingIdeas = "Идеи активистов";
export const cat = [
  sightIdeas,
  botIdeas,
  // landingIdeas
];
export const layerColors = {
  [sightIdeas]: primary,
  [botIdeas]: meadow,
  [landingIdeas]: salmon,
};
export const icons = {
  [sightIdeas]: MEADOW,
  [botIdeas]: NAVY,
  [landingIdeas]: SALMON,
};

export const initialViewState = {
  latitude: 57.98069,
  longitude: 33.246397,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};
