import React from "react";
import { Avatar, Button, Space, Typography } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import CategoryTag from "./category-tag";

const { Paragraph, Text } = Typography;

const IdeaDescription = ({ object }) => {
  const { category, chat_user, cr, id, message, photos } = object ?? {};
  const { first_name } = chat_user ?? {};

  return (
    <Space direction="vertical" size={24}>
      <Space direction="vertical" size={16}>
        {category && <CategoryTag>{category}</CategoryTag>}
        <Space direction="vertical">
          <Space style={{ justifyContent: "space-between" }}>
            {first_name && <Text strong>{first_name}</Text>}
            {cr && (
              <Text type="secondary" strong>
                {moment().to(cr)}
              </Text>
            )}
          </Space>
          {message && <Paragraph>{message}</Paragraph>}
          {photos && (
            <Avatar.Group maxCount={2}>
              {photos?.map(({ url }, i) => (
                <Avatar key={i} src={url} />
              ))}
            </Avatar.Group>
          )}
        </Space>
      </Space>
      {id && (
        <Link to={`/idea/${id}`}>
          <Button size="large">Подробнее</Button>
        </Link>
      )}
    </Space>
  );
};

export default IdeaDescription;
