import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Space, Typography } from "antd";

import Meta from "../components/meta";
import ButtonGroup from "../components/button-group";
import Container from "../components/container";
import IdeasCatalog from "../components/catalog/ideas-catalog";

const { Title } = Typography;

const Ideas = () => {
  const { page } = useSelector(({ app }) => app);
  const buttons = [
    // { name: "Предложить идею", type: "primary", to: "/suggest" },
    { name: "На главную", to: "/" },
  ];

  return (
    <Container>
      <Meta pageTitle="Каталог идей" {...{ page }} />
      <Row>
        <Col offset={2} span={20} xl={{ offset: 4, span: 16 }}>
          <Space direction="vertical" size={24}>
            <Title level={2}>Предложения</Title>
            <IdeasCatalog filter />
            <ButtonGroup {...{ buttons }} />
          </Space>
        </Col>
      </Row>
    </Container>
  );
};

export default Ideas;
