import React from "react";
import styled from "styled-components";
import { Skeleton, Space, Tag } from "antd";

const { CheckableTag } = Tag;

const Container = styled.section`
  .ant-tag-checkable {
    margin-bottom: 0.5rem;
    padding: 0 0.75rem;
    border-color: var(--color-primary);
    font-size: 0.875rem;
    line-height: 1.75rem;

    :not(.ant-tag-checkable-checked) {
      color: var(--color-primary);
    }
  }
`;

const CategoryFilter = ({
  categories,
  currentCategories,
  loading,
  handleChange,
}) => (
  <Container>
    {loading ? (
      <Space>
        {[...Array(3).keys()].map((item) => (
          <Skeleton.Button key={item} size="small" active />
        ))}
      </Space>
    ) : (
      categories?.map((category, i) => (
        <CheckableTag
          key={i}
          checked={currentCategories.indexOf(category) > -1}
          onChange={(checked) => handleChange(category, checked)}
        >
          {category}
        </CheckableTag>
      ))
    )}
  </Container>
);

export default CategoryFilter;
