import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Card, Typography, Space, Button, Skeleton } from "antd";
import moment from "moment";

import CategoryTag from "../category-tag";

const { Text, Paragraph } = Typography;
const { Meta } = Card;

export const cardBase = css`
  .ant-card {
    height: 100%;
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.12);

    &,
    &-body,
    &-meta {
      &,
      &-detail,
      &-description {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

    &-meta-description .ant-space {
      flex: 1;

      &-item {
        :only-child,
        :last-child {
          margin-top: auto;
        }
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  .ant {
    &-tag {
      padding: 0 0.75rem;
      height: 1.75rem;
      font-size: 0.875rem;
      line-height: 1.75;
      border-radius: 0;
    }

    &-btn-link {
      padding: 0;
    }

    &-space-align-center {
      justify-content: space-between;
    }
  }

  ${cardBase}
`;
export const MetaContainer = styled(Space)`
  &,
  > .ant-space-item:last-child {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
export const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  > .ant-typography {
    margin-bottom: 0.25rem;
  }
`;

const IdeaCard = ({ id, category, chat_user, message, cr, loading }) => {
  const { id: userId, full_name } = chat_user ?? {};

  return (
    <Container>
      <Card>
        <MetaContainer direction="vertical" size={24}>
          {loading ? (
            <Skeleton.Button size="small" active />
          ) : (
            category && <CategoryTag>{category}</CategoryTag>
          )}
          <Skeleton {...{ loading }} active>
            <Meta
              description={
                <Space direction="vertical">
                  <UserContainer>
                    {full_name && (
                      <Link to={userId ? `/participant/${userId}` : ""}>
                        <Text strong>{full_name}</Text>
                      </Link>
                    )}
                    {cr && (
                      <Text type="secondary" strong>
                        {moment().to(cr)}
                      </Text>
                    )}
                  </UserContainer>
                  {message && (
                    <Paragraph ellipsis={{ rows: 4 }}>{message}</Paragraph>
                  )}
                  <Button type="link">
                    <Link to={id ? `/idea/${id}` : "/ideas"}>Подробнее</Link>
                  </Button>
                </Space>
              }
            />
          </Skeleton>
        </MetaContainer>
      </Card>
    </Container>
  );
};

export default IdeaCard;
