import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Col, Row, Space, Typography } from "antd";

// import SocialButton from "./social-button";
import privacy from "../pdf/privacy_policy_ru.pdf";
import terms from "../pdf/terms_of_use_ru.pdf";
import { ReactComponent as LogoKB } from "../images/icons/logo-kb.svg";
import moment from "moment";

const { Text, Link } = Typography;

const Container = styled.footer`
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  padding: 3rem 0 4rem;
  background-color: var(--color-grey-lighter);
  font-size: 0.875rem;
`;

const Footer = () => {
  // const app = useSelector(({ app }) => app);
  // const { social } = app?.page ?? {};
  const { page } = useParams();

  return (
    <Container hidden={!page}>
      <Row>
        <Col offset={2} span={20} xl={{ offset: 4, span: 16 }}>
          <Row gutter={[24, 32]}>
            <Col span={24} sm={12} lg={8}>
              <Space direction="vertical" size={16}>
                {/*<Text>*/}
                {/*  <Paragraph>*/}
                {/*    Велоэкспедиция реализуется командой Let&rsquo;s*/}
                {/*    bike&nbsp;it! и&nbsp;КБ&nbsp;Стрелка в&nbsp;рамках проекта*/}
                {/*    веломастерплана Москва&nbsp;&mdash; Санкт Петербург.*/}
                {/*  </Paragraph>*/}
                {/*  <Paragraph>*/}
                {/*    Инициатор и&nbsp;спонсор проекта&nbsp;&mdash; компания*/}
                {/*    Millhouse.*/}
                {/*    <br />*/}
                {/*    Разработчик&nbsp;&mdash; КБ&nbsp;Стрелка, при поддержке*/}
                {/*    Ростуризма.*/}
                {/*  </Paragraph>*/}
                {/*</Text>*/}
                <Space direction="vertical" size={8}>
                  <Text>Разработано на платформе </Text>
                  <Link href="https://чего-хочет-город.рф/">
                    Чего-хочет-город.рф
                  </Link>
                </Space>
                <Space direction="vertical" size={8}>
                  <Text>{`© ООО «КБ Стрелка», ${moment().format(
                    "YYYY"
                  )}`}</Text>
                  <Link href="https://strelka-kb.com/">
                    <LogoKB />
                  </Link>
                </Space>
              </Space>
            </Col>
            {/*<Col span={24} sm={12} lg={8}>*/}
            {/*  <Space direction="vertical" size={16}>*/}
            {/*    <Text>Найдите бот в соцсетях</Text>*/}
            {/*    <Space size={16}>*/}
            {/*      {social &&*/}
            {/*        Object.keys(social).map((key, i) => (*/}
            {/*          <SocialButton key={i} platform={key} link={social[key]} />*/}
            {/*        ))}*/}
            {/*    </Space>*/}
            {/*  </Space>*/}
            {/*</Col>*/}
            <Col span={24} sm={12} lg={8}>
              <Space direction="vertical">
                <Link to={terms} target="_blank" download>
                  Пользовательское соглашение
                </Link>
                <Link to={privacy} target="_blank" download>
                  Положение об обработке и&nbsp;защите персональных данных
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
