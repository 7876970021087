/**
 * External Dependencies
 */
import {
  APP_DATA,
  DIALOG_DATA,
  GET_POP_IDEAS,
  IDEAS_DATA,
  POST_IDEA,
  SET_CATEGORY,
  GET_MAP_IDEAS,
  GET_IDEA,
  GET_CAT_IDEAS,
} from "../actions/app";
import { makeViewState, ideasToGeojson } from "../utils";

const INITIAL_APP_STATE = {
  projectId: "",
  landingId: "",
  dialog: {},
  page: {},
  ideas: {
    ideas: [],
    page: 0,
    total: 0,
  },
  popIdeas: {},
  catIdeas: {},
  sentIdea: {},
  currentIdea: {},
  categories: [],
  currentCategory: 0,
  initialViewState: {},
  mapData: null,
  ideaCategories: [
    { id: 1, name: "Велоинфраструктура", img: "bikes.png" },
    { id: 2, name: "Гаражи и парковки", img: "parkings.png" },
    { id: 3, name: "Городское озеленение", img: "urban-landscaping.png" },
    { id: 4, name: "Детские площадки", img: "playgrounds.png" },
    { id: 5, name: "Домашние животные", img: "pets.png" },
    { id: 6, name: "Дороги", img: "roads.png" },
    { id: 7, name: "Доступная среда", img: "accessability.png" },
    { id: 8, name: "Другое", img: "other.png" },
    { id: 9, name: "Зеленые пространства", img: "green-spaces.png" },
    {
      id: 10,
      name: "Инженерная инфраструктура",
      img: "engineering-infrastructure.png",
    },
    { id: 11, name: "Кафе и магазины", img: "shops-n-food.png" },
    { id: 12, name: "Музеи", img: "museums.png" },
    { id: 13, name: "Мусор и Загрязнение", img: "waste.png" },
    { id: 14, name: "Навигация", img: "navigation.png" },
    { id: 15, name: "Наследие", img: "heritage.png" },
    { id: 16, name: "Образование", img: "education.png" },
    { id: 17, name: "Освещение", img: "lighting.png" },
    { id: 18, name: "Пешеходная инфраструктура", img: "pedestrian.png" },
    { id: 19, name: "Площадки для отдыха", img: "recreation.png" },
    { id: 20, name: "Реклама и брендинг", img: "branding.png" },
    { id: 21, name: "Социальная инфраструктура", img: "social.png" },
    { id: 22, name: "Спорт", img: "sports.png" },
    { id: 23, name: "Строительство", img: "construction-sites.png" },
    { id: 24, name: "Транспорт", img: "transport.png" },
    { id: 25, name: "Туризм", img: "tourism.png" },
    { id: 26, name: "Услуги и сервисы", img: "services.png" },
    { id: 27, name: "Фасады", img: "facades.png" },
  ],
};

export default function (state = INITIAL_APP_STATE, action) {
  switch (action.type) {
    case GET_IDEA:
      return {
        ...state,
        currentIdea: action.payload,
      };
    case APP_DATA:
      return {
        ...state,
        projectId: action.payload.project_id,
        landingId: action.payload.id,
        page: action.payload.data,
        typeId: action.payload.type_id,
        categories: state.ideaCategories.filter(
          (item) => action.payload?.data?.categories?.indexOf(item.id) > -1
        ),
        initialViewState: makeViewState(action.payload.data.location.map),
      };
    case DIALOG_DATA:
      return {
        ...state,
        dialog: action.payload.bots,
      };
    case IDEAS_DATA:
      return {
        ...state,
        ideas: action.payload,
        showCatalog: action.payload.ideas.length > 0,
      };
    case GET_MAP_IDEAS:
      return {
        ...state,
        mapData: ideasToGeojson(action.payload.ideas),
      };
    case GET_POP_IDEAS:
      return {
        ...state,
        popIdeas: action.payload,
        showCatalog: action.payload.ideas.length > 0,
      };
    case GET_CAT_IDEAS:
      return {
        ...state,
        catIdeas: action.payload,
      };
    case POST_IDEA:
      return {
        ...state,
        sentIdea: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    default:
      return state;
  }
}
