import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row, Result, Skeleton, Space, Typography } from "antd";

import Carousel from "../components/carousel";
import Meta from "../components/meta";
import ButtonGroup from "../components/button-group";
import Container from "../components/container";
import { useQuery } from "@apollo/client";
import { IDEA_QUERY } from "../queries";
import moment from "moment";
import { UserContainer } from "../components/ideas/idea-card";
import IdeaMap from "../components/map/idea-map";

const { Title, Text, Paragraph } = Typography;

const IdeaPage = () => {
  const { page } = useSelector(({ app }) => app);
  const { id } = useParams();
  const { loading, error, data } = useQuery(IDEA_QUERY, {
    variables: { id },
  });
  const { idea } = { ...data };
  const { message, photos = [], chat_user, cr, location } = { ...idea };
  const { full_name, id: userId } = { ...chat_user };

  const buttons = [
    // { name: "Предложить идею", type: "primary", to: "/suggest" },
    { name: "Все предложения", to: "/ideas" },
  ];

  return (
    <Container>
      <Meta pageTitle="Предложение" {...{ page }} />
      <Row>
        <Col offset={2} span={20} xl={{ offset: 4, span: 16 }}>
          {!loading && (!idea || error) ? (
            <Result
              status="error"
              title="Предложение не найдено"
              extra={<ButtonGroup key="ideas" />}
            />
          ) : (
            <Space direction="vertical" size={24}>
              <Row gutter={[20, 24]}>
                <Col span={24} md={20}>
                  <Title level={2}>Предложение</Title>
                </Col>
                <Col span={24} md={16}>
                  <Space direction="vertical" size={24}>
                    {loading ? (
                      <Skeleton paragraph={false} />
                    ) : (
                      <UserContainer>
                        {full_name && (
                          <Link to={userId ? `/participant/${userId}` : ""}>
                            <Title level={3}>{full_name}</Title>
                          </Link>
                        )}
                        {cr && (
                          <Text type="secondary" strong>
                            {moment().to(cr)}
                          </Text>
                        )}
                      </UserContainer>
                    )}
                    {loading ? (
                      <Skeleton />
                    ) : (
                      message && <Paragraph>{message}</Paragraph>
                    )}
                    {loading ? (
                      <Skeleton.Image style={{ marginBottom: "2rem" }} />
                    ) : (
                      photos?.length > 0 && (
                        <Carousel images={photos.filter(({ url }) => url)} />
                      )
                    )}
                  </Space>
                </Col>
                <Col span={24} md={8}>
                  <IdeaMap {...{ location, loading }} />
                </Col>
              </Row>
              <ButtonGroup {...{ buttons }} />
            </Space>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default IdeaPage;
