import styled from "styled-components";
import { Layout } from "antd";

const MapContainer = styled(Layout)`
  height: 100%;
  overflow: hidden;
  background-color: var(--color-grey-lighter-alt);

  && .ant-drawer-content-wrapper {
    box-shadow: none;
  }

  .ant-spin {
    .anticon {
      font-size: 2rem;
    }

    &-nested-loading,
    &-container {
      height: 100%;
    }

    &-nested-loading > div > .ant-spin {
      max-height: 100%;
    }
  }
`;

export default MapContainer;
