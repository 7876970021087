import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import styled from "styled-components";
import { IconLayer } from "@deck.gl/layers";

import breakpoints from "../../theme/breakpoints";
import iconAtlas from "../../images/icons/pins.png";
import {
  iconMapping,
  MEADOW,
  initialViewState as viewState,
} from "../../constants";
import BaseMap from "./index";
import { createRouteLayer } from "../../utils";

const MapContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 100%;
  height: 100%;
  width: 100%;
  background-color: var(--color-grey-lighter-alt);

  @media screen and (max-width: ${breakpoints.md}px) {
    padding-bottom: 100%;
  }
`;

const IdeaMap = ({ location, loading }) => {
  const { page } = useSelector(({ app }) => app);
  const { route } = page?.location ?? {};
  const data = location ? [location] : [];

  const routeLayer = createRouteLayer("participant-route-layer", route);
  const ideasLayer = new IconLayer({
    id: "idea-layer",
    data,
    pickable: true,
    iconAtlas,
    iconMapping,
    getIcon: () => `marker${MEADOW}`,
    getPosition: (d) => [d?.lng, d?.lat],
    getSize: 96,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 47],
    alphaCutoff: 0.5,
  });

  const [initialViewState, setInitialViewState] = useState(viewState);
  const layers = [routeLayer, ideasLayer];

  useEffect(() => {
    const { lat: latitude, lng: longitude } = { ...location };

    location &&
      setInitialViewState((state) => ({
        ...state,
        latitude,
        longitude,
        zoom: 8,
      }));
  }, [location]);

  return (
    <Spin spinning={loading}>
      <MapContainer>
        <BaseMap {...{ initialViewState, layers }} isForm />
      </MapContainer>
    </Spin>
  );
};

export default IdeaMap;
