import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useQuery } from "@apollo/client";

import { CATEGORIES_QUERY, IDEAS_QUERY } from "../../queries";
import IdeaCard from "../ideas/idea-card";
import CategoryFilter from "./category-filter";
import Catalog from "./index";

const IdeasCatalog = ({ ids, user, filter }) => {
  const [currentCategories, setCurrentCategories] = useState([]);
  const [current, setCurrent] = useState(1);
  const pageSize = 12;
  const queryCategories =
    currentCategories.length > 0 ? currentCategories : null;
  const { loading, error, data } = useQuery(IDEAS_QUERY, {
    variables: {
      pageSize,
      offset: pageSize * (current - 1),
      categories: queryCategories,
      ids,
      user,
    },
  });
  const { ideas, idea_aggregate } = data ?? {};
  const { count: total = 0 } = idea_aggregate?.aggregate ?? {};
  const { loading: catLoading, error: catError, data: catData } = useQuery(
    CATEGORIES_QUERY
  );
  const { ideas: catIdeas } = catData ?? {};

  const categories =
    catIdeas?.length > 0
      ? catIdeas
          ?.filter(({ category }) => category)
          ?.map(({ category }) => category)
      : [];

  const handleChange = (id, checked) => {
    const selectedCategories = checked
      ? [...currentCategories, id]
      : currentCategories.filter((i) => i !== id);

    setCurrentCategories(selectedCategories);
    setCurrent(1);
  };

  const err = error || catError;

  useEffect(() => {
    err && message.error(err.message);
  }, [err]);

  return (
    <Catalog
      {...{ loading, current, total, pageSize }}
      data={ideas}
      onChange={(page) => setCurrent(page)}
      component={IdeaCard}
      emptyText="Нет предложений"
    >
      {filter && (loading || (!loading && categories)) && (
        <CategoryFilter
          {...{ categories, currentCategories, handleChange }}
          loading={catLoading}
        />
      )}
    </Catalog>
  );
};

export default IdeasCatalog;
