import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, Layout, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import locale from "antd/es/locale/ru_RU";
import moment from "moment";
import "moment/locale/ru";

import client from "../clients/gql-client";
import GlobalStyle from "../theme/global-style";
import Main from "./main";
import Ideas from "./ideas";
import Idea from "./idea";
import Header from "../components/header";
import Footer from "../components/footer";
// import Places from "./places";
// import Place from "./place";
// import Suggest from "./suggest";
import { getAppData } from "../actions/app";
import { project } from "../config";
// import Participants from "./participants";
// import Participant from "./participant";
import ScrollTop from "../components/scroll-top";

const routes = [
  { path: "/", component: Main, exact: true },
  // { path: "/places", component: Places, exact: true },
  // { path: "/place/:id", component: Place, exact: true },
  { path: "/ideas", component: Ideas, exact: true },
  { path: "/idea/:id", component: Idea, exact: true },
  // { path: "/participants", component: Participants, exact: true },
  // { path: "/participant/:id", component: Participant, exact: true },
  // { path: "/suggest", component: Suggest, exact: true },
];

const App = () => {
  const dispatch = useDispatch();

  moment.locale("ru");
  Spin.setDefaultIndicator(<LoadingOutlined />);

  useEffect(() => {
    dispatch(getAppData(project));
  }, [dispatch]);

  return (
    <ApolloProvider {...{ client }}>
      <ConfigProvider {...{ locale }}>
        <Router>
          <GlobalStyle />
          <ScrollTop />
          <Layout>
            <Layout.Header>
              <Route path="/:page?">
                <Header />
              </Route>
            </Layout.Header>
            <Layout.Content>
              <Switch>
                {routes.map((route, i) => (
                  <Route key={i} {...route} />
                ))}
                <Redirect to="/" />
              </Switch>
            </Layout.Content>
            <Layout.Footer style={{ padding: 0 }}>
              <Route path="/:page?">
                <Footer />
              </Route>
            </Layout.Footer>
          </Layout>
        </Router>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default App;
