import React from "react";
import { Button, Space, Typography } from "antd";
import { Link } from "react-router-dom";

import TextSection from "./text-section";

const { Text } = Typography;

const PlaceDescription = ({ object }) => {
  const { id, description, ideas } = object ?? {};

  const meta = [
    { title: "Описание", description: description || "Нет описания" },
    ...(ideas?.length > 0
      ? [
          {
            title: "Количество предложений",
            description: <Text strong>{ideas?.length}</Text>,
          },
        ]
      : []),
  ];

  return (
    <Space direction="vertical" size={24}>
      <Space direction="vertical">
        {meta?.map(({ title, description }, i) => (
          <TextSection key={i} {...{ title, description }} />
        ))}
      </Space>
      {id && (
        <Link to={`/place/${id}`}>
          <Button size="large">Подробнее</Button>
        </Link>
      )}
    </Space>
  );
};

export default PlaceDescription;
