import React, { useEffect, useState } from "react";
import { IconLayer } from "@deck.gl/layers";
import { FlyToInterpolator } from "@deck.gl/core";
import styled from "styled-components";
import { Grid, Layout, message, Spin } from "antd";
import { useQuery } from "@apollo/client";

import iconAtlas from "../../images/icons/pins.png";
import BaseMap from "./index";
import {
  IDEAS_QUERY,
  // LOCATIONS_QUERY
} from "../../queries";
// import { restClient } from "../../clients/gql-client";
// import LayerControl from "../layer-control";
import {
  botIdeas,
  // cat,
  iconMapping,
  icons,
  initialViewState,
  ROSE,
  sightIdeas,
} from "../../constants";
import MapSider from "../map-sider";
import MapContainer from "./map-container";
import { useSelector } from "react-redux";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const Container = styled.div`
  position: relative;
  height: 100vh;
  min-height: 25rem;
`;
const PlacesMap = () => {
  const { page } = useSelector(({ app }) => app);
  const { location } = { ...page };
  const { map } = { ...location };

  // const initialSwitches = cat.reduce(
  //   (obj, key) => ({ ...obj, [key]: true }),
  //   {}
  // );
  const [viewState, setViewState] = useState(initialViewState);

  useEffect(() => {
    const { lng: longitude, lat: latitude } = {
      ...map,
    };

    longitude &&
      latitude &&
      setViewState((viewState) => ({
        ...viewState,
        latitude,
        longitude,
      }));
  }, [map]);

  // const [switches, setSwitches] = useState(initialSwitches);
  const [visible, setVisible] = useState(false);
  const [object, setObject] = useState();
  const [hovered, setHovered] = useState();
  const { id: objectId } = object ?? {};
  const { id: hoveredId } = hovered ?? {};
  const { lg } = useBreakpoint();

  const { loading: spinning, error, data } = useQuery(IDEAS_QUERY);
  const { ideas } = { ...data };

  // const {
  //   data: sData,
  //   loading: sLoading,
  //   error: sError,
  // } = useQuery(LOCATIONS_QUERY, { client: restClient });
  // const { features } = sData?.locations ?? {};
  // const err = error || sError;
  // const spinning = loading || sLoading;

  useEffect(() => {
    error && message.error("Ошибка загрузки данных");
  }, [error]);

  // const handleSwitches = (layer, checked) => {
  //   setSwitches((state) => ({ ...state, [layer]: checked }));
  // };

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => setObject(undefined), 200);
  };

  const isMatching = (id, match) => id && id === match;

  const getSize = ({ id, properties }) => {
    const isMatchingSize = (match) =>
      isMatching(id, match) || isMatching(properties?.id, match);

    return isMatchingSize(hoveredId) || isMatchingSize(objectId) ? 128 : 96;
  };

  const onClick = ({ object, coordinate }) => {
    const [longitude, latitude] = coordinate || [];
    const { properties, ideas } = { ...object };

    longitude &&
      latitude &&
      setViewState((state) => {
        const { zoom } = state ?? {};

        return {
          ...state,
          longitude,
          latitude,
          zoom: zoom >= 8 ? zoom : 8,
          transitionInterpolator: new FlyToInterpolator({
            speed: 1,
          }),
          transitionDuration: "auto",
        };
      });
    setVisible(true);
    setObject(properties ? { ...properties, ideas } : object);
  };

  const onHover = ({ object }) => {
    setHovered(object?.properties || object);
  };

  const handleData = () =>
    // name === sightIdeas
    //   ? features || []
    //   :
    ideas?.filter(
      ({ location }) => !!location // && category === name
    );

  const handlePosition = ({ geometry, location }, name) =>
    name === sightIdeas
      ? geometry?.coordinates
      : [location?.lng, location?.lat];

  const handleIcon = ({ id, properties }, name) =>
    `marker${
      isMatching(id, objectId) || isMatching(properties?.id, objectId)
        ? ROSE
        : icons[name]
    }`;

  const createIdeasLayer = (name) =>
    new IconLayer({
      id: name,
      data: handleData(name),
      pickable: true,
      iconAtlas,
      iconMapping,
      getIcon: (object) => handleIcon(object, name),
      getPosition: (object) => handlePosition(object, name),
      getSize,
      autoHighlight: true,
      highlightColor: [255, 255, 255, 47],
      alphaCutoff: 0.5,
      onClick,
      onHover,
      updateTriggers: {
        getIcon: [objectId],
        getSize: [objectId, hoveredId],
      },
      // visible: switches[name] ?? true,
    });

  // const layers = [...cat.map((category) => createIdeasLayer(category))];
  const layers = [createIdeasLayer(botIdeas)];

  return (
    <MapContainer>
      <Content>
        <Spin {...{ spinning }}>
          <Container>
            <BaseMap
              {...{ viewState, layers }}
              onViewStateChange={({ viewState }) => setViewState(viewState)}
              controller={lg}
              // controls={
              //   <LayerControl layers={switches} onChange={handleSwitches} />
              // }
            />
          </Container>
        </Spin>
      </Content>
      <MapSider
        {...{ visible, object }}
        bordered={false}
        onCancel={handleClose}
        breakpoint={lg}
      />
    </MapContainer>
  );
};

export default PlacesMap;
