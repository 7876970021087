import React from "react";
import { Space, Typography } from "antd";

const { Paragraph, Text } = Typography;

const TextSection = ({ title, description }) => (
  <Space direction="vertical" size={4}>
    <Text type="secondary" strong>
      {title}
    </Text>
    <Paragraph>{description || "Нет описания"}</Paragraph>
  </Space>
);

export default TextSection;
