import { createGlobalStyle } from "styled-components";

import colors from "./colors";
import breakpoints from "./breakpoints";

const {
  primary,
  primaryDark,
  white,
  black,
  greyLight,
  greyLighter,
  greyLighterAlt,
  grey,
  greyDark,
  greyDarker,
  primaryLight,
  primaryLighter,
} = colors;

const GlobalStyle = createGlobalStyle`
  :root { 
    --color-primary: ${primary};
    --color-primary-dark: ${primaryDark};
    --color-primary-light: ${primaryLight};
    --color-primary-lighter: ${primaryLighter};
    --color-white: ${white};
    --color-black: ${black};
    --color-grey-light: ${greyLight};
    --color-grey-lighter: ${greyLighter};
    --color-grey-lighter-alt: ${greyLighterAlt};
    --color-grey: ${grey};
    --color-grey-dark: ${greyDark};
    --color-grey-darker: ${greyDarker};
  }
  
  html, body, #root, .ant-layout {
    height: 100%; 
  }
  
  body, input, textarea {
    font-weight: 300;
    letter-spacing: 0.02em;
  }
  
  .ant{
    &-layout{
      &-content {
        min-height: auto;
      }
    
      &-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        padding: 0;
        width: 100%;
      }
    }
  
    &-btn,
    &-radio-button-wrapper {
      font-size: 0.875rem;
    }
    
    &-select-item {
      &, &-empty {
        font-weight: 300;
      }
    }
    
    @media screen and (max-width: ${breakpoints.sm}px) {
      &-list-pagination{
        text-align: left;
    
        .ant-pagination {
          display: flex;
          justify-content: space-between;
          
          ::after,
          ::before {
            display: none;
          }
        }
      }
    }
    
    &-space {
      width: 100%;
    }
    
    &-result &-space {
      width: auto;
    }
    
    &-avatar-string {
      font-weight: 400;
      line-height: 1.9;
      margin-left: -0.0625rem;
    }
    
    
    &-typography:not(.ant-typography-secondary) strong {
      color: rgba(0, 0, 0, 0.75);
    }
  }
  
  @media screen and (max-width: ${breakpoints.sm}px) {
    h2.ant-typography, .ant-typography h2 {
      font-size: 2rem;
    }
  }
`;

export default GlobalStyle;
