import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ pageTitle }) => {
  const title = "Валдай inFields";

  return (
    <Helmet titleTemplate={`%s - ${title}`} defaultTitle={title}>
      {/* General tags */}
      <title>{pageTitle || ""}</title>
      <meta name="description" content={title} />
      {/* OpenGraph tags */}
      {typeof window !== "undefined" && (
        <meta name="og:url" content={window.location.href} />
      )}
      <meta
        name="og:title"
        content={title + (pageTitle ? ` - ${pageTitle}` : "")}
      />
      <meta name="og:description" content={title} />
      <meta name="og:image" content="https://files.chg.one/valday/valday.png" />
      <meta name="og:type" content="website" />
    </Helmet>
  );
};

export default Meta;
