/**
 * Created by stainwoortsel on 24.04.2020.
 */

const env = {
  base: "https://api.xn-----glccfbc4ebdaxw3bzag.xn--p1ai",
  ws: "ws://api.xn-----glccfbc4ebdaxw3bzag.xn--p1ai",
  auth: "/v1/project",
  landing: "/v1/landing",
  dialog: "/v1/dialog",
  data: "/data",
};

export const appConfig = {
  api: {
    base: env.base,
    ws: env.ws,
    type: "json",
    landing: env.base + env.landing,
    dialog: env.base + env.dialog,
    data: env.base + env.data,
  },
  auth: {
    secret: env.jwt_secret,
  },
  isProduction: process.env.NODE_ENV === "production",
  version: process.env.VERSION,
};

export const project = "valday_in_fields";

export const ApiEndpoint = function (api) {
  this.apiName = api;
  this.apiBase = appConfig.api[this.apiName];
  this.endpoint = function (endpoint, params) {
    if (endpoint.length && endpoint[0] !== "/") {
      endpoint = "/" + endpoint;
    }
    if (params) {
      endpoint +=
        "?" +
        Object.keys(params)
          .map((k) => {
            return k + "=" + params[k];
          })
          .join("&");
    }

    return this.apiBase + endpoint;
  };
};

export const apiUrl = (api, endpoint) => {
  return appConfig.api[api] + endpoint;
};

export const WebLink = (entity, city, param) => {
  switch (entity) {
    case "idea":
      return "/" + city + "/catalog/idea/" + param;
    default:
      return "/";
  }
};
