import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";

import { AUTH_QUERY } from "../queries";
import { project } from "../config";
import { isTokenExpired } from "../utils";

const endpoint = process.env.REACT_APP_ENDPOINT;
const daDataKey = process.env.REACT_APP_DADATA_API_KEY;
const daDataEndpoint = process.env.REACT_APP_DADATA_ENDPOINT;
const tokenKey = "cwd-valday-token";

export const defaultOptions = {
  query: {
    errorPolicy: "all",
  },
};

export const handleHeaders = (headers, token) => ({
  headers: {
    ...headers,
    Authorization: token ? `Bearer ${token}` : "",
  },
});

const authLink = new RestLink({
  uri: endpoint + "/v1/auth",
});

export const authClient = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

export const link = createHttpLink({
  uri: endpoint + "/v1/graphql",
});

const getProjectToken = async (slug) =>
  await authClient
    .query({
      query: AUTH_QUERY,
      variables: {
        slug,
      },
    })
    .then((res) => res?.data?.token?.access_token)
    .catch((err) => console.error(err?.message));

const withProjectToken = (project) =>
  setContext(async (_, { headers }) => {
    const projectToken = localStorage.getItem(tokenKey);

    if (projectToken && !isTokenExpired(projectToken)) {
      return handleHeaders(headers, projectToken);
    }

    const token = await getProjectToken(project);
    token && typeof token === "string" && localStorage.setItem(tokenKey, token);

    return handleHeaders(headers, token);
  });

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case "invalid-jwt":
          case "invalid-headers":
            const { headers } = operation.getContext();

            return fromPromise(getProjectToken(project)).flatMap((token) => {
              token && localStorage.setItem(tokenKey, token);
              operation.setContext(handleHeaders(headers, token));

              return forward(operation);
            });
          default:
            graphQLErrors.map(({ message }) => console.warn(message));
        }
      }
    }

    if (networkError) {
      console.warn(networkError);
    }
  }
);

const client = new ApolloClient({
  link: from([errorLink, withProjectToken(project), link]),
  cache: new InMemoryCache(),
  defaultOptions,
});

const restLink = new RestLink({
  uri: endpoint + `/v1/landing/city/${project}`,
});

export const restClient = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

const daDataLink = new RestLink({
  uri: daDataEndpoint,
  responseTransformer: async (response) =>
    response
      .json()
      .then(({ suggestions }) => suggestions)
      .catch((error) => console.error(error)),
  headers: {
    Authorization: daDataKey ? `Token ${daDataKey}` : "",
  },
});

export const daDataClient = new ApolloClient({
  link: daDataLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

export default client;
