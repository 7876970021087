import React from "react";
import { Button, Grid, Space } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const { useBreakpoint } = Grid;

const ButtonLink = styled(Link)`
  display: inline-block;
  width: 100%;
`;

const ButtonGroup = ({ buttons, breakpoint }) => {
  const { sm } = useBreakpoint();
  const isMobile = breakpoint || !sm;

  return (
    <Space size={16} direction={isMobile ? "vertical" : "horizontal"}>
      {buttons?.map(({ name, type, to }, i) => (
        <ButtonLink
          {...{ to }}
          key={i}
          component={({ href }) => (
            <Button {...{ href, type }} size="large" block={isMobile}>
              {name}
            </Button>
          )}
        />
      ))}
    </Space>
  );
};

export default ButtonGroup;
