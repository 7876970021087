import React from "react";
import styled from "styled-components";
import { Tag } from "antd";

const CategoryTag = styled((props) => (
  <Tag color="var(--color-primary)" {...props} />
))`
  padding: 0 0.75rem;
  height: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 0;
`;

export default CategoryTag;
