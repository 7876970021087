/**
 * Styles
 */
// import './style.scss';

/**
 * External Dependencies
 */
import React from "react";
import DeckGL from "@deck.gl/react";
import { MapController } from "@deck.gl/core";
import ReactMapGL, {
  _MapContext as MapContext,
  NavigationControl,
} from "react-map-gl";
import styled from "styled-components";
// import { Space, Grid } from "antd";

// import StyleControl from "./style-control";
// import PlaceSearch from "../place-search";
import breakpoints from "../../theme/breakpoints";

const { lg } = breakpoints;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// const { useBreakpoint } = Grid;

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${({ isForm }) => (isForm ? "1rem" : "2rem")};
  width: 100%;
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  @media screen and (min-width: ${lg}px) {
    margin-top: ${({ isForm }) => !isForm && "4rem"};
  }

  > .ant {
    &-space-vertical {
      align-items: flex-end;
    }

    &-select {
      max-width: 20rem;
      width: 100%;
    }
  }

  .mapboxgl-ctrl-group {
    border-radius: 0;

    :not(:empty) {
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);
    }

    button:focus {
      border-radius: 0;
      box-shadow: 0 0 0.0625rem 0.0625rem var(--color-primary);
    }
  }
`;

// const mapboxStyle = "mapbox://styles/mapbox/";

// const styleOptions = [
//   { name: "Схема", value: "mapbox://styles/thxena/ckdejulun2zpt1inzuqtcz2ah" },
//   { name: "Спутник", value: mapboxStyle + "satellite-v9" },
//   {
//     name: "Гибрид",
//     value: mapboxStyle + "satellite-streets-v11",
//   },
// ];

const BaseMap = ({
  children,
  controller,
  onSelect,
  isForm,
  controls,
  ...props
}) => {
  // const [mapStyle, setMapStyle] = useState(styleOptions?.[0]?.value);
  // const { lg } = useBreakpoint();
  const mapStyle = "mapbox://styles/thxena/ckdejulun2zpt1inzuqtcz2ah";

  return (
    <DeckGL
      ContextProvider={MapContext.Provider}
      controller={controller || { type: MapController, scrollZoom: false }}
      {...props}
    >
      <ControlsContainer {...{ isForm }}>
        {/*<PlaceSearch {...{ onSelect }} />*/}
        {/*<Space direction={!isForm && lg ? "horizontal" : "vertical"} size={16}>*/}
        {/*  <PlaceSearch {...{ onSelect }} />*/}
        {/*  <StyleControl*/}
        {/*    options={styleOptions}*/}
        {/*    onChange={(e) => setMapStyle(e.target.value)}*/}
        {/*  />*/}
        {/*</Space>*/}
        {controls}
        <NavigationControl showCompass={false} />
      </ControlsContainer>
      <ReactMapGL
        {...{ mapStyle }}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        attributionControl={false}
        preventStyleDiffing
      />
      {children}
    </DeckGL>
  );
};

export default BaseMap;
