import React, { useState } from "react";
import styled from "styled-components";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Grid,
  // Skeleton
} from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import breakpoints from "../theme/breakpoints";

const { md: medium } = breakpoints;
const { useBreakpoint } = Grid;

const Container = styled.nav`
  font-size: 0.875rem;
  font-weight: 700;
  background-color: var(--color-white);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);

  @media screen and (min-width: ${medium}px) {
    padding: 0 3rem;
  }

  .ant {
    &icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: ${({ isOpen }) => (isOpen ? "100vh" : "4rem")};
      height: 100%;
      width: 100%;

      @media screen and (max-width: ${medium - 0.02}px) {
        align-items: flex-start;
      }
    }

    &-skeleton {
      width: 12.5rem;
      margin-right: 2rem;
    }
  }

  a {
    color: var(--color-grey-darker);

    :hover {
      color: var(--color-primary);
    }
  }
`;
const Inner = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${medium - 0.02}px) {
    flex-direction: column;
  }
`;
const Title = styled(Link)`
  margin-right: 4rem;
  white-space: nowrap;
`;
const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    padding: 0;
    width: 100%;
    list-style: none;

    @media screen and (max-width: ${medium - 0.02}px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.5rem;
    }

    li {
      :not(:last-child) {
        margin-right: 1.5rem;
      }

      :nth-last-child(2) {
        margin-right: ${({ isOpen }) => (isOpen ? "auto" : "2rem")};

        @media screen and (max-width: ${medium - 0.02}px) {
          margin-bottom: 1.5rem;
        }
      }

      @media screen and (max-width: ${medium - 0.02}px) {
        line-height: 2.5rem;
      }

      a {
        padding-bottom: 0.375rem;
        border-bottom: 0.125rem solid transparent;
        transition: all 0.3s ease-in-out;

        :hover {
          border-bottom-color: var(--color-primary);
        }
      }
    }
  }
`;
const MenuButton = styled(Button)`
  margin: 1rem 0 1rem 1.5rem;
`;

const links = [
  // { name: "Локации", to: "/places" },
  { name: "Предложения", to: "/ideas" },
  // { name: "Участники", to: "/participants" },
];

const Header = () => {
  // const { page } = useSelector(({ app }) => app);
  // const { title } = { ...page };
  const { md, lg } = useBreakpoint();
  const [visible, setVisible] = useState(false);
  const Icon = visible ? CloseOutlined : MenuOutlined;

  return (
    <Container isOpen={visible}>
      <Row>
        <Col offset={2} span={20} md={{ offset: 0, span: 24 }}>
          <Inner>
            {
              ((!lg && !visible) || !md || lg) && (
                // (title ? (
                <Title to="/">Валдай inFields</Title>
              )
              // ) : (
              //   <Skeleton paragraph={false} />
              // ))
            }
            {((!lg && visible) || lg) && (
              <LinksContainer isOpen={visible}>
                <ul>
                  {links.map(({ name, to }, i) => (
                    <li key={i}>
                      <Link {...{ to }}>{name}</Link>
                    </li>
                  ))}
                  {/*<li>*/}
                  {/*  <Link to="/suggest">*/}
                  {/*    <Button type="primary" size="large">*/}
                  {/*      Предложить идею*/}
                  {/*    </Button>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              </LinksContainer>
            )}
          </Inner>
          {!lg && (
            <MenuButton
              type="text"
              icon={<Icon />}
              onClick={() => setVisible(!visible)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
